// Styles for desktop version of the transactions table

.tx-table {
    border-collapse: collapse;
    width: 100%;

    thead {
        font-weight: 500;
        color: #6c757e;
        border: none;
        .card--tabbed & {
            background: var(--tx-table-thead-alt-background);
        }
    }

    td, th {
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 0;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
    }

    th {
        text-align: left;
        font-weight: 500;
        border: none;
    }

    tbody.sub-list tr:not(.tx-table-row-details) td {
        border-top: none;
    }

    &__cell {
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 0 18px;
        box-sizing: border-box;
        width: 100%;
        &--align-right {
            justify-content: flex-end;
        }
        &--align-center {
            justify-content: center;
        }
    }

    &__badge {
        display: inline-block;
        vertical-align: middle;
        padding: 6px 0;
        min-width: 46px;
        box-sizing: border-box;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        border-radius: 4px;
        &--in {
            color: var(--badge-green-color);
            background-color: var(--badge-green-background-color);
        }
        &--out {
            color: var(--badge-red-color);
            background-color: var(--badge-red-background-color);
        }
        &--swap {
            color: var(--badge-blue-color);
            background-color: var(--badge-blue-background-color);
        }
        &--service {
            color: var(--badge-grey-color);
            background-color: var(--badge-grey-background-color);
        }
        &--skeleton {
            color: transparent;
            background-color: var(--body-light-muted-color);
        }
    }
}

@media screen and (max-width: 600px) {
    .tx-table {
        table-layout: fixed;
        tbody {
            &.sub-list td {
                padding: 2px 0 18px 0;
            }

            tr, td {
                max-width: auto;
            }

            tr {
                td {
                    border-top-width: 0;
                }
                &:first-child:not(:last-child):not(.skeleton-row) td {
                    padding-bottom: 6px;
                }
                &:last-child:not(:first-child):not(.skeleton-row) td {
                    padding-top: 6px;
                }
            }
        }
    }
}

.tx-history-empty-panel {
    padding: 24px 0;
    text-align: center;
    font-weight: 500;
}

.tx-history-wrap {
    overflow-x: auto;
    scrollbar-width: none;
    width: 100%;
}

.tx-table-cell-icon {
    display: inline-flex;
    padding: 4px;
    box-sizing: border-box;
    background: var(--body-light-muted-color);
    color: var(--tx-table-icon-color);
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 0 12px;
    &--in {
        color: var(--tx-table-icon-in-color);
        background: var(--tx-table-icon-in-background);
    }
    &--out {
        color: var(--tx-table-icon-out-color);
        background: var(--tx-table-icon-out-background);
    }
    &--blue {
        color: var(--badge-blue-color);
        background: var(--badge-blue-background-color);
    }
    &--service {
        color: var(--badge-grey-color);
        background-color: var(--badge-grey-background-color);
    }
    &--error {
        color: var(--tx-table-icon-error-color);
        background: var(--tx-table-icon-error-background);
        &:hover {
            color: var(--tx-table-icon-error-hover-color) !important;
        }
    }
    svg {
        width: 16px;
        height: 16px;
    }
    &:hover {
        color: var(--body-text-color);
    }
}

.tx-table-operation-icon {
    position: absolute;
    right: 1px;
    width: 14px;
    height: 14px;
}

.tx-table-inner-container {
    display: flex;
    flex-wrap: wrap;
}

.tx-table-inner {
    display: inline-flex;
    flex-direction: column;
    padding: 12px 20px;
    max-width: 70%;
    word-wrap: break-word;
    white-space: normal;
    &__header {
        font-weight: 500;
        font-size: 1.05em;
        margin-bottom: 6px;
        display: block;
    }
}

.tx-table-expand-caret {
    position: relative;
    width: 14px;
    height: 14px;
    cursor: pointer;
    padding: 10px;
    margin: -10px;
    &--expanded {
        transform: rotate(180deg);
    }
}

.tx-table-row-details {
    box-shadow: 0 -2px 0 0 var(--card-border-color) inset;
    background: var(--tx-table-details-background-color);
}

.tx-table-cell-skeleton {
    display: block;
    width: 75%;
    background: var(--body-light-muted-color);
    border-radius: 6px;
    height: 1em;
    margin-bottom: -1px;
}
